import React from 'react';
import { Container , Row , Col } from 'react-bootstrap';
import FooterImage from "../../../Assets/img/footer-image.png";
import Logo from "../../../Assets/img/IDC-logo.png";
import { Strings } from '../../../Localization/Content';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faFacebookF, faYoutube,  faTwitter,  faLinkedinIn } from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
    return ( 
        <>
            <footer className='page-Footer'>
                <Container fluid>
                    <Row className='align-items-end'>
                        <Col md={5} className="px-0">
                            <div className='cont-img-big'>
                                <img src={FooterImage} alt="logo" />
                                <div className='copy-right-text'>
                                    <p>Copyright IDC, {new Date().getFullYear()}. All rights reserved.</p>
                                </div>
                            </div>
                        </Col>

                        <Col md={7}>
                            <div className='cont'>
                                <div className='cont-img'>
                                    <img src={Logo} alt="logo" />
                                </div>
                                <div className='cont-text'>
                                    <p>{Strings.Footer_more_Information} <a href={`mailto:${Strings.IDC_email_link}`}>{Strings.IDC_email_link}</a></p>
                                </div>

                                <div className="icons-parent">
                                    <a href={Strings.facebook_link} rel="noreferrer" className="follow-icon" target="_blank">
                                        <FontAwesomeIcon icon={faFacebookF} />
                                    </a>
                                    <a href={Strings.twitter_link} rel="noreferrer" className="follow-icon" target="_blank">
                                        <FontAwesomeIcon icon={faTwitter} />
                                    </a>
                                    <a href={Strings.linkedin_link} rel="noreferrer" className="follow-icon" target="_blank"> 
                                        <FontAwesomeIcon icon={faLinkedinIn} />
                                    </a>
                                    <a href={Strings.youtube_link} rel="noreferrer" className="follow-icon" target="_blank">
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </footer>
        </>
    );
}

export default Footer;