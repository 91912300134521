const Strings = {
    "hero_sec_title":"Artificial intelligence",
    "hero_sec_desc":"the DNA of Saudi Arabia’s transformation",
    "Footer_more_Information":"For more information e-mail us",
    "IDC_email_link":"idcksareport@idc.com",
    "strategic_Sponsers_Title":"STRATEGIC PARTNER",
    "Our_Sponsers_Title":"SPONSORS",
    "Story_First_desc":`Saudi Arabia’s Vision 2030 is a long-term blueprint that sets out transformational strategies for developing “an ambitious nation, a thriving economy and a vibrant society”.`,
    "Story_Second_desc" : `The ICT sector has been one of the major enablers of the Kingdom’s Vision 2030. Across industries - be it the national data management platform, the national identity and access management platform, the digitization of citizen accounts program or the human capital development portals – digital technologies continue to play a key enabling role in the Vision Realization Programs (VRPs) to help make the Kingdom’s Vision 2030 a reality. In this context, digital transformation is a continuous process in the Kingdom by which public and private sector entities adapt to or drive disruptive changes.`,
    "DOWNLOAD":"DOWNLOAD",
    "About_IDC":"About IDC",
    "About_IDC_Desc":`International Data Corporation (IDC) is the premier global provider of market intelligence, advisory services, and events for the information technology, telecommunications, and consumer technology markets. With more than 1,300 analysts worldwide, IDC offers global, regional, and local expertise on technology, IT benchmarking and sourcing, and industry opportunities and trends in over 110 countries. IDC's analysis and insight helps IT professionals, business executives, and the investment community to make fact-based technology decisions and to achieve their key business objectives. Founded in 1964, IDC is a wholly owned subsidiary of International Data Group (IDG), the world's leading tech media, data, and marketing services company. To learn more about IDC, please visit <a href="https://www.idc.com" target="_blank">www.idc.com</a>. Follow IDC on Twitter at @IDC and LinkedIn. Subscribe to the IDC Blog for industry news and insights.`,
    "IDC_IN_Saudi":"IDC in Saudi Arabia",
    "IDC_IN_Saudi_Desc":`Saudi Arabia is one of IDC’s key focus areas and IDC has been tracking the Saudi market for over 25 years. IDC has had a strong footprint in Saudi Arabia for almost a decade, with a dedicated team of consultants, analysts, conference executives, project managers, and relationship managers based out of the Riyadh office. IDC is closely aligned with the Kingdom's transformative initiatives and was the first international ICT advisory firm to author a report on the impact of ICT on the ambitious National Transformation Program (NTP). Building on its local presence and long involvement in Saudi Arabia, IDC has forged several long-term partnerships with local Saudi organizations, both in the public and private sectors. Several public sector ICT enablers have relied on IDC for over a decade to conduct nationwide research. IDC has also helped local telecom operators in the Kingdom by advising them on the convergence of telecom and IT services, including strategic recommendations on their products and services portfolio and the provision of go-to-market advice. IDC has also helped several local IT Service providers to transform and align themselves to market needs by providing market / opportunity assessment advisory services.`,
    "facebook_link":"https://www.facebook.com/IDCMEA/",
    "twitter_link":"https://twitter.com/IDCMEA",
    "linkedin_link":"https://www.linkedin.com/company/idc-mea/",
    "youtube_link":"https://www.youtube.com/channel/UChR7m9sBU83y8Av5g_OvILg",
    "previous_books_title":"If you like this report, check previous IDC Annual Executive Reports here"
};


export {Strings};