import React from 'react';
import Login from '../../Auth/Login/Login';
import Dashboard from './Dashboard/Dashboard';

const AdminView = () => {
    return ( 
        <>

            <Login/>
            {/* <Dashboard/> */}
        </>
    );
}

export default AdminView;