import React from 'react';
import HeroSection from './HeroSection/HeroSection';
import OurSponsers from './SponsersSection/SponsersSection';
import StorySection from './StorySection/StorySection';
import MainBooksSection from './MainBooksSection/MainBooksSection';
import PreviousBooksSlider from './PreviousReportsSection/PreviousReportsSection';
import AboutSection from './AboutSection/AboutSection';
import Header from '../../layouts/Header/Header';
import Footer from '../../layouts/Footer/Footer';
const LandingPage = () => {
    return ( 
        <>
            <Header/>
            <HeroSection/>
            <OurSponsers/>
            <StorySection/>
            <MainBooksSection/>
            <PreviousBooksSlider/>
            <AboutSection/>
            <Footer/>
        </>
     );
}
 
export default LandingPage;