import React ,{useRef , useEffect , useState}  from 'react';
import Header from '../../../layouts/Header/Header';
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import { baseUrl } from '../../../../Config';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import './Dashboard.scss';
const Dashboard = () => {
    const navigate = useNavigate();
    const tableRef = useRef(null);
    const [usersData,SetUseresData] =useState([]);

    useEffect(() => {
        if(localStorage.getItem('AdminToken') && localStorage.getItem('AdminToken').length > 0) {

            const headers = {'NTPAuthHEADER':`${localStorage.getItem('AdminToken')}`}
            axios.get(`${baseUrl}/GetAllUsers`,{ headers }).then(res => {
                        SetUseresData(res.data);
                        console.log(res.data);
                    }).catch(err => {
                        console.table(err);
                        console.log(err?.response.status)
                        if(err?.response.status == 401) {
                            console.log('unauthoeriezed!');
                            localStorage.removeItem("AdminToken");
                            navigate("/")
                        }
                    })
        
        }else {
            navigate("/login");
        }
    }, []);


    const handleLogOut = ()=> {
        if(localStorage.getItem('AdminToken')) {
            localStorage.removeItem("AdminToken");
            navigate("/")
        } else {
            navigate("/login")
        }
    }


    return ( 
        <>
            <div className='relative-parent'>
                <Header/>
                <div className='export-parent'>
                    <DownloadTableExcel filename="users table" sheet="users" currentTableRef={tableRef.current}>
                        <button className='btn btn-success btn-lg'>
                            <FontAwesomeIcon icon={faFileExport} className="mx-2"/>
                            Export
                        </button>
                    </DownloadTableExcel>
                    
                    <button className='btn btn-secondary btn-lg' onClick={handleLogOut}>
                        Logout
                    </button>
                </div>
            </div>
            <div className='w-80 px-3' >
                    <Table striped bordered hover variant="dark" responsive ref={tableRef}>
                        
                        <tbody>
                            <tr>
                                <th>#</th>
                                <th>Full Name</th>
                                <th>country</th>
                                <th>Organization</th>
                                <th>Employee</th>
                                <th>Industry</th>
                                <th>Job Title</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Language</th>
                            </tr>

                            {
                                usersData.length > 0 ? 
                                usersData.map((user , index) => {
                                    return(
                                        <tr>
                                            <td>{index}</td>
                                            <td>{user?.name}</td>
                                            <td>{user?.country}</td>
                                            <td>{user?.organization}</td>
                                            <td>{user?.companySize}</td>
                                            <td>{user?.industry}</td>
                                            <td>{user?.jobTitle}</td>
                                            <td>{user?.email}</td>
                                            <td>{user?.phone}</td>
                                            <td>{user?.language}</td>
                                        </tr>
                                    )
                                }) : 
                                <tr>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td className='none'></td>
                                    <td colSpan={10} className="text-center"> no data to show here</td>
                                </tr>
                            }

                        </tbody>
                    </Table>
            </div>
                    
        </>
    );
}

export default Dashboard;