import React from 'react';
import { Strings } from '../../../../Localization/Content';
import sponserOne from '../../../../Assets/img/sponsers/sponserOne.png';
import sponserTwo from '../../../../Assets/img/sponsers/sponserTwo.png';
import sponserThree from '../../../../Assets/img/sponsers/sponserThree.png';
import sponserFour from '../../../../Assets/img/sponsers/sponserFour.png';
import sponserFive from '../../../../Assets/img/sponsers/sponserFive.png';
import sponserSix from '../../../../Assets/img/sponsers/sponserSix.png';
import sponserSeven from '../../../../Assets/img/sponsers/sponserSeven.png';
import { AnimationOnScroll } from 'react-animation-on-scroll';


const OurSponsers = () => {
    return ( 
        <>
            <section className='sponsers-sec'>
                <div className='cont'>
                    <AnimationOnScroll animateIn="animate__fadeInDown" duration={2}>
                        <div className='cont-text'>
                            <h2>{Strings.strategic_Sponsers_Title}</h2>
                        </div>
                    </AnimationOnScroll>
                    <div className='cont-img'>
                        {/* <AnimationOnScroll animateIn="animate__slideInLeft" duration={3}>
                            <img src={sponserOne} alt="site"/>
                        </AnimationOnScroll> */}
                        <AnimationOnScroll animateIn="animate__slideInRight" duration={3}>
                            <img src={sponserTwo} alt="sdaia"/>
                        </AnimationOnScroll>
                    </div>
                </div>
                <AnimationOnScroll animateIn="animate__slideInUp" duration={5}> 
                    <div className='cont-text-second'>
                        <h2>{Strings.Our_Sponsers_Title}</h2>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__slideInUp" duration={5}> 
                    <div className='other-sponsers-parent w-80'>
                        <div className='cont-img-sm'>
                            <img src={sponserOne} alt="site"/>
                        </div>
                        <div className='cont-img-sm'>
                            <img src={sponserFour} alt="2P"/>
                        </div>
                        <div className='cont-img-sm'>
                            <img src={sponserSeven} alt="crayan"/>
                        </div>
                        
                        
                        
                    </div>
                </AnimationOnScroll>
            </section>
        </>
    );
}

export default OurSponsers;