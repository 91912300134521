import React , {useState , useContext , useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import ModalOpend from '../../../Context/ModalOpenOrNot/ModalOpenOrNot';
import RegisteredOrNot from '../../../Context/RegisteredOrNot/RegisteredOrNot';
import { baseUrl } from '../../../Config';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import { AllCountries } from '../../../Localization/Countries';
import 'react-toastify/dist/ReactToastify.css';


const ModalParent = () => { 

    const [Registered , setRegistered ] = useContext(RegisteredOrNot);
    const [ModalOpendState , setModalOpendState ] = useContext(ModalOpend);
    const[USERipAddress,SetUSERIpAddress] = useState('');
    const[USERFullName,SetUSERFullName] = useState('');
    const[USERCountry,SetUSERCountry] = useState(null);
    const[USEROrginzation,SetUSEROrignization] = useState('');
    const[USERJobTitle,SetUSERJobTitle] = useState('');
    const[USERIndustry,SetUSERIndustry] = useState('');
    const[USEREmail,SetUSEREmail] = useState('');
    const[USERPhoneNumber,SetUSERPhoneNumber] = useState('');
    const[USERLanguage,SetUSERLanguage] = useState('english');
    const[USERCompanySize,SetUSERCompanySize] = useState('0-99');

    const[WrongEmail,SetWrongEmail]= useState(false);
    const[canSubmit,setCanSubmit]= useState(false);



    const handleModal = () => {
        setModalOpendState(!ModalOpendState)
    }


    useEffect(()=>{
        axios.get(`https://ifconfig.me`).then( (res) => {
            SetUSERIpAddress(res.data);
        })
    },[])


    const handleChangeNumumbers = (e) => {
        const theVal =  e.target.value.replace(/[^0-9]/gi,"");
        if (e.target.name === "PhoneNumber") {
            SetUSERPhoneNumber(theVal);
        } 
    };

    const handleValidateEmailBlur = (e)=> {
        const emailValue = e.target.value;
        if(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailValue)) {
            SetWrongEmail(false)
            if(USERipAddress.length > 0 && USERFullName.length > 0 && USERCountry.length > 0 && USEROrginzation.length > 0 && USERJobTitle.length > 0 && USERIndustry.length > 0 && USEREmail.length > 0  && USERPhoneNumber.length > 0  && USERLanguage.length > 0  && USERCompanySize.length > 0) {
                setCanSubmit(true)
            } else {
                setCanSubmit(false) 
            }
        } else {
            setCanSubmit(false)
            SetWrongEmail(true)
        }
    }

    const handleChange = (e) => {
        if (e.target.name === "FullName") {
            SetUSERFullName(e.target.value)
        } else if (e.target.name === "Country") {
            SetUSERCountry(e.target.value)
        } else if (e.target.name === "Oragnization") {
            SetUSEROrignization(e.target.value)
        } else if (e.target.name === "Industry") {
            SetUSERIndustry(e.target.value)
        } else if (e.target.name === "JobTitle") {
            SetUSERJobTitle(e.target.value)
        } else if (e.target.name === "Email") {
            SetUSEREmail(e.target.value)
        } else if (e.target.name === "PhoneNumber") {
            SetUSERPhoneNumber(e.target.value)
        }  else if (e.target.name === "languageSelect") {
            SetUSERLanguage(e.target.value);
        } else if (e.target.name === "CompanySizeSelect") {
            SetUSERCompanySize(e.target.value)
        }

        
    }

    const handleBlur = () => {
        if(USERipAddress.length > 0 && USERFullName.length > 0 && Object.keys(USERCountry).length > 0 && USEROrginzation.length > 0 && USERJobTitle.length > 0 && USERIndustry.length > 0 && USEREmail.length > 0  && USERPhoneNumber.length > 0  && USERLanguage.length > 0  && USERCompanySize.length > 0 ) {
            setCanSubmit(true) 
        } else {
            setCanSubmit(false)
        }
    }

    const handleChangeCountry = (country) => {
        SetUSERCountry(country)
    }



    
    const handleRegister = (e) => {
        e.preventDefault();
        const userObject = {
            'name':USERFullName,
            'country':USERCountry?.value,
            'organization':USEROrginzation,
            'email':USEREmail,
            'phone':USERPhoneNumber,
            'language':USERLanguage,
            'jobTitle':USERJobTitle,
            'industry':USERIndustry,
            'companySize':USERCompanySize,
            'ipAddress':USERipAddress
        }

        axios({
            method: "post",
            url: `${baseUrl}/register`,
            data:JSON.stringify(userObject),
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            console.log('user regiestered successfully');
            console.log(res.data);
            setRegistered(true);
            sessionStorage.setItem("registeredUserID", res.data);
            sessionStorage.setItem("prefferedLanguage", USERLanguage);
            localStorage.setItem("userId", res.data);
            toast.success('Click Download To Download The Document', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
            setModalOpendState(false)
        }).catch(err => {
            console.log(err , 'error message')
        })
    }


    //const userBody = `id = ${localStorage.getItem("userId")}`;

    // const testapiUser = (e) => {
    //     axios({
    //         method: "post",
    //         url: `${baseUrl}/user`,
    //         data:userBody,
    //         headers: { "Content-Type": "application/json" },
    //         params:{id :localStorage.getItem("userId")}
    //     }).then((res) => {
    //         // console.log('i get the data successfully');
    //         // console.log(res.data);
    //         // setRegistered(true);
    //         // localStorage.setItem("userId", res.data);
    //     }).catch(err => {
    //         console.log(err , 'error message')
    //     })
    // }


    return ( 
        <>
        <ToastContainer position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"/>
            <div className='idc-modal-parent'>
                <div className='idc-modal-body'>
                    <div className='idc-modal-header d-flex justify-content-between align-items-center'>
                        <h2>Registration</h2>
                        <div className='close-icon-parent' onClick={handleModal}>
                            <FontAwesomeIcon icon={faXmark} />
                        </div>
                    </div>
                    <Form onSubmit={handleRegister}>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control type="text" name='FullName' placeholder="Full Name" onChange={handleChange} onBlur={handleBlur}/>
                        </Form.Group>
                        
                        <Form.Group className="mb-3" controlId="formBasicCountry">
                            <Form.Label>Country</Form.Label>
                            <Select
                                value={USERCountry}
                                onChange={handleChangeCountry}
                                options={AllCountries}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicOriganization">
                            <Form.Label>Organization</Form.Label>
                            <Form.Control type="text" placeholder="Organization" name="Oragnization" onChange={handleChange} onBlur={handleBlur}/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Select Employee</Form.Label>
                            <Form.Select name="CompanySizeSelect" onChange={handleChange} onBlur={handleBlur}>
                                <option disabled defaultValue>Select Employee</option>
								<option value="0-99" defaultValue>0-99</option>
								<option value="100-499">100-499</option>
								<option value="500-999">500-999</option>
								<option value="1000-4999">1000-4999</option>
								<option value="5000+">5000+</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicIndustry">
                            <Form.Label>Industry</Form.Label>
                            <Form.Control type="text" placeholder="Industry"  name="Industry" onChange={handleChange} onBlur={handleBlur}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicJob">
                            <Form.Label>Job Title</Form.Label>
                            <Form.Control type="text" placeholder="Job Title" name="JobTitle" onChange={handleChange} onBlur={handleBlur}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="text" placeholder="Email" name="Email" onChange={handleChange} onBlur={handleValidateEmailBlur}/>
                            {WrongEmail && <p className='error-message'>invalid Email , Enter a vlid email !</p>}
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicphoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control type="text" placeholder="PhoneNumber" value={USERPhoneNumber} name="PhoneNumber" onBlur={handleBlur} onChange={handleChangeNumumbers}/>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Language</Form.Label>
                            <Form.Select name="languageSelect" onChange={handleChange} onBlur={handleBlur}>
								<option value="english" defaultValue>English</option>
								<option value="arabic">العربية</option>
                            </Form.Select>
                        </Form.Group>
                        
                        <div className='form-controls-parent d-flex justify-content-end align-items-center mt-4'>
                            <Button variant="primary" type="submit" disabled={canSubmit?false:true}>
                                Submit
                            </Button>

                            <Button variant="secondary" type="button" onClick={handleModal}>
                                Close
                            </Button>
                        </div>
                    </Form>
                </div>
            </div>
        </>
    );
}

export default ModalParent;