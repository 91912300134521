import React from 'react';
import { Container , Row , Col } from 'react-bootstrap';
import Logo from "../../../Assets/img/IDC-logo.png";
const Header = () => {
    return ( 
        <>
            <header className='page-header'>
                <Container fluid>
                    <Row className='w-80'> 
                        <Col>
                            <div className='cont-img'>
                                <img src={Logo} alt="logo" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
        
        </>
    );
}

export default Header;