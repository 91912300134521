import {  useState  } from "react";
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import "animate.css/animate.min.css";
import './App.scss';
import LandingPage from "./components/pages/Landing/Index";
import RegisteredOrNot from "./Context/RegisteredOrNot/RegisteredOrNot";
import ModalOpend from "./Context/ModalOpenOrNot/ModalOpenOrNot";
import AdminView from "./components/pages/Admin/Admin";
import Dashboard from "./components/pages/Admin/Dashboard/Dashboard";
const  App = () => {

  const [Registered,setRegistered] = useState(false);
  const [ModalOpendState,setModalOpendState] = useState(false)
  
  return (
    <>
      <RegisteredOrNot.Provider value={[Registered,setRegistered]}> 
        <ModalOpend.Provider value={[ModalOpendState,setModalOpendState]}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<AdminView />} />
              <Route path='/dashboard' element={<Dashboard/>}/>


              <Route path="*" element={<LandingPage />} />
            </Routes>
          </BrowserRouter>
        </ModalOpend.Provider>
      </RegisteredOrNot.Provider>
    </> 
  );
}

export default App;
