import React , {useContext } from 'react';
import { Container , Row , Col} from 'react-bootstrap';
import EnglishBook from '../../../../Assets/img/english-report.png';
import ArabichBook from '../../../../Assets/img/arabic-report.png';
import { Strings } from '../../../../Localization/Content';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import RegisteredOrNot from '../../../../Context/RegisteredOrNot/RegisteredOrNot';
import ModalParent from '../../../layouts/Modal/Modal';
import ModalOpend from '../../../../Context/ModalOpenOrNot/ModalOpenOrNot';
import axios from 'axios';
import { baseUrl } from '../../../../Config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const MainBooksSection = () => {


    const [Registered , setRegistered ] = useContext(RegisteredOrNot);
    const [ModalOpendState , setModalOpendState ] = useContext(ModalOpend);

    const handleModal = () => {
        setModalOpendState(!ModalOpendState)
    }

    const handleGetPdfLink = (bookName) => {
        const requestParams = {
            "downloadedName": bookName,
            "userId": sessionStorage.getItem("registeredUserID")
        }
        axios({
                method: "post",
                url: `${baseUrl}/PdfDownload`,
                data:JSON.stringify(requestParams),
                headers: { "Content-Type": "application/json" },
                responseType: 'blob'
                }).then((res) => {
                    toast.success('your Book Downloaded successfully', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                    // fileDownload(res.data, 'filename.pdf');
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${bookName}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }).catch(err => {
                    console.log(err , 'error message')
                    toast.error('Some Thing Went Wrong', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                })
    }

    return ( 
        <>
            <section className='main-books-sec'>
            <ToastContainer position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"/>

                {
                    ModalOpendState && <ModalParent/>
                }
                <Container fluid>
                    <Row className='w-80 align-items-center'>
                        <Col md={6}>
                            <div className='books-cont'>
                                <AnimationOnScroll animateIn="animate__rotateInDownLeft">
                                    <div className='cont-img'>
                                        <img src={EnglishBook} alt="artifical intelligence" />
                                    </div>
                                </AnimationOnScroll>
                                <div className='cont-button' >
                                    {
                                        Registered ? 
                                        <button onClick={ () => handleGetPdfLink('PSR_2022_Artificial_Intelligence_EN')} className="download-btn btn">{Strings.DOWNLOAD}</button>
                                        :
                                        <button className="btn download-btn" onClick={handleModal}>{Strings.DOWNLOAD}</button>
                                    }
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='books-cont reverse-mobile'>
                                <div className='cont-button'>
                                    {
                                        Registered ?  
                                        <button onClick={ () => handleGetPdfLink('PSR_2022_Artificial_Intelligence_AR')} className="download-btn btn">{Strings.DOWNLOAD}</button>
                                        :
                                        <button className="btn download-btn" onClick={handleModal}>{Strings.DOWNLOAD}</button>
                                    }
                                </div>
                                <AnimationOnScroll animateIn="animate__rotateInDownRight">
                                    <div className='cont-img'>
                                        <img src={ArabichBook} alt="artifical intelligence" />
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
 
export default MainBooksSection;