import React from 'react';
import {Strings} from '../../../../Localization/Content';
import HeroImage from '../../../../Assets/img/hero-image.png';
import { Col, Container , Row } from 'react-bootstrap';

import HeroAnimation from './Paticals';

const HeroSection = () => {
    return ( 
        <>
            <section className='hero-sec'>
                <HeroAnimation/>
                <Container>
                    <Row>
                        <Col>
                            <div className='cont'>
                                <div className='cont-text text-center'>
                                    <h1>{Strings.hero_sec_title}</h1>
                                    <h3>{Strings.hero_sec_desc}</h3>
                                </div>
                                <div className='cont-img'>
                                    <img src={HeroImage} alt="" /> 
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default HeroSection;