import React from 'react';
import { Strings } from '../../../../Localization/Content';
import { AnimationOnScroll } from 'react-animation-on-scroll';
const AboutSection = () => {
    return ( 
        <>

            <section className='about-sec w-80'>
                <div className='cont'>
                    <AnimationOnScroll animateIn="animate__fadeInRight" duration={3}>
                        <div className='cont-text mb-5 text-center'>
                            <h2>{Strings.About_IDC}</h2>
                            <p dangerouslySetInnerHTML={{__html: Strings.About_IDC_Desc}}></p>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeInLeft" duration={3}>
                        <div className='cont-text text-center'>
                            <h2>{Strings.IDC_IN_Saudi}</h2>
                            <p>{Strings.IDC_IN_Saudi_Desc}</p>
                        </div>
                    </AnimationOnScroll>
                </div>
            
            </section>
        </>
    );
}

export default AboutSection;