import React , {useContext , useState} from 'react';
import Slider from "react-slick";
import cyberSecuirty from '../../../../Assets/img/cyberSecuirty.png';
import digitalVision from '../../../../Assets/img/digital_vision.png';
import EnvisioningApproach from '../../../../Assets/img/envisioningApproacch.png';
import realizingAspiration from '../../../../Assets/img/realizingAspirations.png';
import { PreviousReports } from '../../../../Localization/PreviousReports';
import { Strings } from '../../../../Localization/Content';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import RegisteredOrNot from '../../../../Context/RegisteredOrNot/RegisteredOrNot';
import ModalOpend from '../../../../Context/ModalOpenOrNot/ModalOpenOrNot';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { baseUrl } from '../../../../Config';


const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    lazyLoad: true,
    responsive: [
        {
            breakpoint: 1366,
            settings: {
                arrows:false,
                slidesToShow: 3,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 1024,
            settings: {
                arrows:false,
                slidesToShow: 2,
                slidesToScroll: 1,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 600,
            settings: {
                arrows:false,
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                arrows:false,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};

const PreviousBooksSlider = () => {
    const [Registered , setRegistered ] = useContext(RegisteredOrNot);
    const [ModalOpendState , setModalOpendState ] = useContext(ModalOpend); 


    const[prefferedLangue,serPrefferedLangue] = useState('');

    const handleModal = () => {
        setModalOpendState(!ModalOpendState)
    }

    


    const handleGetPdfLink = (bookName) => {
        const requestParams = {
            "downloadedName": bookName,
            "userId": sessionStorage.getItem("registeredUserID")
        }
        axios({
                method: "post",
                url: `${baseUrl}/PdfDownload`,
                data:JSON.stringify(requestParams),
                headers: { "Content-Type": "application/json" },
                responseType: 'blob'
                }).then((res) => {
                    toast.success('your Book Downloaded successfully', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                    // fileDownload(res.data, 'filename.pdf');
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${bookName}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                }).catch(err => {
                    console.log(err , 'error message')
                    toast.error('Some Thing Went Wrong', {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        });
                    });
    }


    return (  
        <>
            <section className='prvious-books-sec'>
            <ToastContainer position="top-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"/>
                <div className='cont-previous'>
                    <div className='cont-text'>
                        <h2>{Strings.previous_books_title}</h2>
                    </div>
                </div>
                <div className='slider-parent w-80'>
                    <Slider {...settings}>
                        <div className='report-cont'>
                            <div className='cont-img'>
                                <img src={cyberSecuirty} alt=""/>
                            </div>
                            <div className='cont-text'>
                                <h2>{PreviousReports.ReportOneTitle}</h2>
                            </div>
                            <div className='download-parent'>
                            {
                                Registered ? 
                                <button onClick={ () => handleGetPdfLink(`${sessionStorage.getItem("prefferedLanguage") == "english" ? 'Cybersecurity_and_its_impact_on_digital_Saudi' : 'Cybersecurity_and_its_impact_on_digital_Saudi_AR'}`)} className="download-btn btn">{Strings.DOWNLOAD}</button>
                                :
                                <button className="btn download-btn" onClick={handleModal}>{Strings.DOWNLOAD}</button>
                            }
                            </div>
                        </div>

                        <div className='report-cont'>
                            <div className='cont-img'>
                                <img src={digitalVision} alt=""/>
                            </div>
                            <div className='cont-text'>
                                <h2>{PreviousReports.ReportTwoTitle}</h2>
                            </div>
                            <div className='download-parent'>
                                {
                                    Registered ? 
                                    <button onClick={ () => handleGetPdfLink(`${sessionStorage.getItem("prefferedLanguage") == "english" ? 'A_Digital_Government_Vision_for_the_Kingdom_of_Saudi_Arabia_EN' : 'A_Digital_Government_Vision_for_the_Kingdom_of_Saudi_Arabia_AR'}`)} className="download-btn btn">{Strings.DOWNLOAD}</button>
                                    :
                                    <button className="btn download-btn" onClick={handleModal}>{Strings.DOWNLOAD}</button>
                                }
                            </div>
                        </div>

                        <div className='report-cont'>
                            <div className='cont-img'>
                                <img src={EnvisioningApproach} alt=""/>
                            </div>
                            <div className='cont-text'>
                                <h2>{PreviousReports.ReportThreeTitle}</h2>
                            </div>
                            <div className='download-parent'>
                                {
                                    Registered ? 
                                    <button onClick={ () => handleGetPdfLink(`${sessionStorage.getItem("prefferedLanguage") == "english" ? 'National_Transformation_Plan' : 'National_Transformation_Plan'}`)} className="download-btn btn">{Strings.DOWNLOAD}</button>
                                    :
                                    <button className="btn download-btn" onClick={handleModal}>{Strings.DOWNLOAD}</button>
                                }
                            </div>
                        </div>

                        <div className='report-cont'>
                            <div className='cont-img'>
                                <img src={realizingAspiration} alt=""/>
                            </div>
                            <div className='cont-text'>
                                <h2>{PreviousReports.ReportFourTitle}</h2>
                            </div>
                            <div className='download-parent'>
                                {
                                    Registered ? 
                                    <button onClick={ () => handleGetPdfLink(`${sessionStorage.getItem("prefferedLanguage") == "english" ? 'Realizing_Saudi_Arabias_Digital_Aspirations_The_Journey_of_Transformation_EN' : 'AR_Realizing_Saudi_Arabias_Digital_Aspirations_The_Journey_of_Transformation'}`)} className="download-btn btn">{Strings.DOWNLOAD}</button>
                                    :
                                    <button className="btn download-btn" onClick={handleModal}>{Strings.DOWNLOAD}</button>
                                }
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        </>
    );
}

export default PreviousBooksSlider;