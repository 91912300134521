import React from 'react';
import { Container , Row , Col } from 'react-bootstrap';
import StoryImage from '../../../../Assets/img/story-image.png';
import { Strings } from '../../../../Localization/Content';

const StorySection = () => {
    return ( 
        <>
            <section className='story-sec'> 
                <Container fluid>
                    <Row>
                        <Col>
                            <div className='story-cont'>
                                <img src={StoryImage} alt="city" />
                                
                                    <div className='cont-text'>
                                        <p>{Strings.Story_First_desc}</p>
                                        <p>{Strings.Story_Second_desc}</p>
                                    </div>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
 
export default StorySection;