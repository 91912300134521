import React , {useState} from 'react';
import HeroImage from '../../../Assets/img/hero-image.png';
import { Container , Row , Col  } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import { baseUrl } from '../../../Config';
import { useNavigate } from "react-router-dom";





const Login = () => {
    const [userName,setUserName] = useState('');
    const [UserPassword,setUserPassword] = useState('');

    const [worngLogin , setWrongLogin] = useState(false);


    const navigate = useNavigate();

    const handleChange = (e) => {
        if(e.target.name === "userName") {
            setUserName(e.target.value);
        } else if ( e.target.name === "password") {
            setUserPassword(e.target.value)
        }
    } 

    const handleLogin = (e) => {
        e.preventDefault();
        const userObject = {
            'username':userName,
            'password':UserPassword,
        }

        axios({
            method: "post",
            url: `${baseUrl}/Login`,
            data:JSON.stringify(userObject),
            headers: { "Content-Type": "application/json" },
        }).then((res) => {
            console.log('user logged in successfully');
            console.log(res.data ,'this is user ID');
            localStorage.setItem("AdminToken", res.data);
            navigate("/dashboard")
        }).catch(err => {
            setWrongLogin(true);
            console.log(err , 'error message')
        })
    }


    

    // const getBookDownload = (e) => {
    //     const requestParams = {
    //         "downloadedName": "National_Transformation_Plan",
    //         "userId": "0cae666d-04df-4630-949a-23a4cb1dbe0e"
    //     }
    //     axios({
    //             method: "post",
    //             url: `${baseUrl}/PdfDownload`,
    //             data:JSON.stringify(requestParams),
    //             headers: { "Content-Type": "application/json" }
    //             }).then((res) => {
    //                 console.log('hello file downloaded')
    //             }).catch(err => {
    //                 console.log(err , 'error message')
    //             })
    // }




    return ( 
        <>
            <section className='login-screen-parent'>
                <Container>
                    <Row className='align-items-center'>
                        <Col md={6}>
                            <div className='cont-img'>
                                <img src={HeroImage} alt='' />
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className='login-form-parent'>
                                <Form onSubmit={handleLogin}>
                                    <Form.Group className="mb-4" controlId="formBasicUserNAme">
                                        <Form.Label>User Name</Form.Label>
                                        <Form.Control type="text" placeholder="Enter UserName" onChange={handleChange} name='userName'/>
                                    </Form.Group>

                                    <Form.Group className="mb-4" controlId="formBasicPassword">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Password" onChange={handleChange} name='password'/>
                                    </Form.Group>

                                    {
                                        worngLogin && 
                                        <div className='login-error-message'>
                                            username or password are invalid , please try again
                                        </div>
                                    }

                                    <button className='btn download-btn mt-5' type="submit">
                                        Login
                                    </button>

                                    

                                    {/* <button className='btn download-btn mt-5' onClick={getBookDownload}>
                                        get pdf file
                                    </button> */}
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Login;